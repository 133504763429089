// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "current_style";
@import "themes/opgelucht";
@import "themes/stimulus";
@import "themes/cinnux";

@mixin overridesTheme($theme) {
  // app-navbar {
  //   color: mat.get-theme-color($theme, primary);

  mat-toolbar {
    background-color: #fff;
  }
  mat-card {
    background-color: #fff;
  }
}

$default-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$rose-palette,
      tertiary: mat.$rose-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

:root {
  --font: "Roboto";
}

$current-theme: $default-theme;

@if $brand == "cinnux" {
  $current-theme: $cinnux-theme;
  :root {
    --font: "Source Sans Pro";
  }
} @else if $brand == "opgelucht" {
  $current-theme: $opgelucht-theme;
  :root {
    --font: "Sora";
  }
} @else if $brand == "stimulus" {
  $current-theme: $stimulus-theme;
  :root {
    --font: "Source Sans Pro";
  }
}

@include mat.color-variants-backwards-compatibility($current-theme);

:root {
  @include mat.all-component-themes($current-theme);
  @include mat.color-variants-backwards-compatibility($current-theme);
  @include mat.system-level-colors($current-theme);
  @include overridesTheme($current-theme);

  --mdc-list-list-item-leading-avatar-color: #fff;
  .mat-mdc-slide-toggle {
    .mdc-switch__track::before {
      border: none;
    }
    --mdc-switch-unselected-handle-color: var(--sys-primary);
    --mdc-switch-unselected-pressed-handle-color: var(--sys-primary);
    --mdc-switch-unselected-pressed-state-layer-color: var(--sys-primary);
    --mdc-switch-unselected-hover-state-layer-color: var(--sys-primary);
    --mdc-switch-unselected-hover-handle-color: var(--sys-primary);
    --mdc-switch-unselected-focus-state-layer-color: var(--sys-primary);
    --mdc-switch-unselected-focus-handle-color: var(--sys-primary);
    --mdc-switch-unselected-track-color: #e0e0e0;
    --mdc-switch-unselected-pressed-track-color: #e0e0e0;
    --mdc-switch-unselected-hover-track-color: #e0e0e0;
    --mdc-switch-unselected-focus-track-color: #e0e0e0;
    --mdc-switch-selected-handle-color: var(--sys-primary);
    --mdc-switch-selected-pressed-handle-color: var(--sys-primary);
    --mdc-switch-selected-pressed-state-layer-color: var(--sys-primary);
    --mdc-switch-selected-hover-state-layer-color: var(--sys-primary);
    --mdc-switch-selected-hover-handle-color: var(--sys-primary);
    --mdc-switch-selected-focus-state-layer-color: var(--sys-primary);
    --mdc-switch-selected-focus-handle-color: var(--sys-primary);
    --mdc-switch-selected-track-color: #e0e0e0;
    --mdc-switch-selected-pressed-track-color: #e0e0e0;
    --mdc-switch-selected-hover-track-color: #e0e0e0;
    --mdc-switch-selected-focus-track-color: #e0e0e0;
  }
  --mat-app-background-color: #f8f8f8;
  --sys-title-large-font: var(--font);
  --sys-title-medium-font: var(--font);
  --sys-title-small-font: var(--font);

  --sys-body-large-font: var(--font);
  --sys-body-medium-font: var(--font);
  --sys-body-small-font: var(--font);

  --sys-label-large-font: var(--font);
  --sys-label-medium-font: var(--font);
  --sys-label-small-font: var(--font);

  --sys-display-large-font: var(--font);
  --sys-display-medium-font: var(--font);
  --sys-display-small-font: var(--font);
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/RobotoFlex-VariableFont.ttf") format("truetype");
}

@import "scss/SourceSansPro";
@import "scss/Montserrat";
@import "scss/MaterialIcons";

@import "@ctrl/ngx-emoji-mart/picker";
@import "scss/quill.snow";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.text-right {
  text-align: right;
}
.flex {
  display: flex;
}

.timepicker-backdrop-overlay {
  z-index: 1001;
}
.timepicker-overlay {
  z-index: 1002;
}
