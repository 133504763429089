// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use "sass:map";
@use "@angular/material" as mat;

body.opgelucht {
  @font-face {
    font-family: "Sora";
    src: url("./../assets/fonts/Sora-VariableFont_wght.ttf") format("truetype");
  }
}

// Note: Color palettes are generated from primary: #00333D, secondary: #99CDFF, tertiary: #A174EE
$_palettes: (
  primary: (
    0: #000000,
    10: #001f26,
    20: #033640,
    25: #14414b,
    30: #224c57,
    35: #2f5863,
    40: #3b646f,
    50: #547d89,
    60: #6e97a3,
    70: #88b2be,
    80: #a3cdda,
    90: #bfeaf6,
    95: #d8f6ff,
    98: #f0fbff,
    99: #f8fdff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #001d32,
    20: #003353,
    25: #003e64,
    30: #014a75,
    35: #195682,
    40: #29628f,
    50: #467ba9,
    60: #6195c4,
    70: #7cb0e1,
    80: #97cbfd,
    90: #cee5ff,
    95: #e8f2ff,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #270058,
    20: #41008b,
    25: #4d1896,
    30: #5928a2,
    35: #6536af,
    40: #7144bc,
    50: #8b5ed7,
    60: #a679f3,
    70: #be98ff,
    80: #d5bbff,
    90: #ecdcff,
    95: #f7edff,
    98: #fef7ff,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1a1c1d,
    20: #2f3131,
    25: #3a3c3c,
    30: #454748,
    35: #515353,
    40: #5d5f5f,
    50: #767778,
    60: #909191,
    70: #aaabac,
    80: #c6c6c7,
    90: #e2e2e3,
    95: #f0f1f1,
    98: #f9f9fa,
    99: #fcfcfd,
    100: #ffffff,
    4: #0c0f0f,
    6: #121414,
    12: #1e2021,
    17: #282a2b,
    22: #333536,
    24: #37393a,
    87: #dadada,
    92: #e8e8e9,
    94: #eeeeee,
    96: #f3f3f4,
  ),
  neutral-variant: (
    0: #000000,
    10: #161d1f,
    20: #2a3234,
    25: #353d3f,
    30: #41484a,
    35: #4c5456,
    40: #586062,
    50: #71787b,
    60: #8b9294,
    70: #a5acaf,
    80: #c0c8ca,
    90: #dde4e6,
    95: #ebf2f5,
    98: #f3fafd,
    99: #f8fdff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$opgelucht-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
      use-system-variables: true,
    ),
    typography: (
      use-system-variables: true,
    ),
  )
);
